<template>
  <div class="coordinateddevelopment">
    <div class="banner_container contactus_banner border_text_box">
      <div class="border_text">Coordinated development</div>
      <h1 class="border_text_title">多元产业 协同发展</h1>
    </div>
    <div class="bread_crumbs container_width">
      <span>您的位置：首页 > </span>
      <span class="current">联系我们</span>
    </div>
    <service-card></service-card>
    <div class="drop_system_container">
        <div class="drop_system_box border_text_box container_width">
            <h2 class="border_text">Fully advertising system</h2>
            <h3 class="border_text_title">全自动广告投放系统</h3>
            <ul class="drop_list">
                <li class="left">
                    <div class="title">有效控制成本</div>
                    <div class="content">通过自动广告设置，您可以掌控广告在网站上的展示位置。</div>
                </li>
                <li>
                    <div class="title">有效控制成本</div>
                    <div class="content">通过自动广告设置，您可以掌控广告在网站上的展示位置。</div>
                </li>
                <li>
                    <div class="title">有效控制成本</div>
                    <div class="content">通过自动广告设置，您可以掌控广告在网站上的展示位置。</div>
                </li>
                <li>
                    <div class="title">有效控制成本</div>
                    <div class="content">通过自动广告设置，您可以掌控广告在网站上的展示位置。</div>
                </li>
                <li>
                    <div class="title">有效控制成本</div>
                    <div class="content">通过自动广告设置，您可以掌控广告在网站上的展示位置。</div>
                </li>
            </ul>
            <div class="drop_bottom_box">
                <img src="../assets/imgs/coordinateddevelopment/drop_list_bottom.webp" alt="">
                <div class="bottom_right">
                    <div class="title">处理无误报的请求</div>
                    <div class="content">Scrapy 是业界非常流行的爬虫框架，通用、易用、稳定性强。Crawlab 对 Scrapy 有很强的支持，能够让开发者在界面上查看、添加、编辑设置参数、爬虫、Items、Pipelines，将对 Scrapy 的操作进行了可视化优化，让开发者更轻松的集成 Scrapy 爬虫。我们使用内置的实时分析帮助组织在出价前和点击后阶段中消除欺诈，从而释放您的员工的时间来专注于您的业务，而我们帮助您更准确地阻止广告欺诈。允许营销人员根据真实的客户活动（而不是僵尸程序或恶意软件的活动）做出更好的决策，从而最大化您的支出以获得真实结果。</div>
                    <a href="javascript:;" class="a_button">查看更多</a>
                </div>
            </div>
        </div>
    </div>
    <div class="features_container">
        <div class="Features_box border_text_box container_width">
            <h2 class="border_text">Features</h2>
            <h3 class="border_text_title">功能介绍</h3>
            <p class="description">这是一个企业级的开箱即用的爬虫管理平台，包含很多针对爬虫调度监控的实用功能，例如节点注册发现、任务调度、文件编辑、结果查看、日志管理等。 这些功能帮助爬虫开发者专注于页面抓取，而不用担心生产环境中要求的调度、监控、储存等繁琐的逻辑，大幅提升开发者的工作效率。</p>
            <ul class="features_list">
                <li>
                    <div class="title">节点管理</div>
                    <div class="content">天然支持分布式爬虫，因此支持多节点管理，让爬虫程序能够在多个节点、多台服务器上运行，以便最大化利用带宽及计算资源。</div>
                </li>
                <li>
                    <div class="title">任务管理</div>
                    <div class="content">任务指的是运行一次爬虫程序的过程，我们都会将此过程管理起来，让开发者更加方便的了解自己编写的爬虫程序的可用性和有效性。</div>
                </li>
                <li>
                    <div class="title">定时任务</div>
                    <div class="content">定时任务也是核心模块之一。开发者可以利用定时任务定期抓取目标网站。我们提供了可视化编辑，让开发者能够快速定义定时任务的周期。</div>
                </li>
                <li>
                    <div class="title">Scrapy 爬虫</div>
                    <div class="content">Scrapy 是业界非常流行的爬虫框架，通用、易用、稳定性强。Crawlab 对 Scrapy 有很强的支持，能够让开发者在界面上查看、添加、编辑设置参数、爬虫、Items、Pipelines，将对 Scrapy 的操作进行了可视化优化，让开发者更轻松的集成 Scrapy 爬虫。</div>
                </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import '../assets/scss/animation.scss'
import '../assets/scss/communal.scss'
import ServiceCard from "@/components/ServiceCard";
export default {
  name: "coordinateddevelopment",
  components: {
    ServiceCard
  },

}
</script>

<style lang="scss">
@import '../assets/scss/layout.scss';
@import '../assets/iconfont/iconfont.css';
.coordinateddevelopment {
    .contactus_banner {
        background: url('../assets/imgs/coordinateddevelopment/banner.webp') 50% 50%;
        background-size: cover;
        .border_text_title {
          color: #fff
        }
    }
    .serve_container {
        background: #fff;
        .serve_box {
            padding: 60px 0 70px;
            .server_list_box {
                li {
                    opacity: 1;
                }
            }
            .content_line {
                margin-top: 90px;
            }
        }
    }
    .drop_system_container {
        .drop_system_box {
            .drop_list {
                height: 830px;
                padding-top: 60px;
                display: grid;
                grid-template-columns: repeat(3, 30%);
                justify-content: space-between;
                align-content: space-between;
                grid-auto-rows: auto;
                color: #fff;
                .left {
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 1;
                    grid-row-end: 3;
                }
                li {
                    @include flexleft();
                    justify-content: flex-end;
                    color: #ffffff;
                    padding: 0 0 20px 17px;
                    position: relative;
                    overflow: hidden;
                    .title {
                        font-size: 24px;
                        font-weight: bold;
                        line-height: 36px;
                        transition: margin 0.5s;
                    }
                    .content {
                        font-size: 13px;
                        text-align: left;
                        line-height: 20px;
                        opacity: 0;
                    }
                }
                li::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(136deg,#73e0a9, #5b68df 100%);
                    opacity: 0;
                }
                li:hover::before {
                    -webkit-animation: fade-in-tl 0.6s ;
                    animation: fade-in-tl 0.6s ;
                    animation-fill-mode: both;
                }
                li:hover > .content {
                    -webkit-animation: fade-in-bottom-custom 1;
                    animation: fade-in-bottom-custom 1s;
                    animation-fill-mode: both;
                }
                li:hover > .title {
                    margin-bottom: 20px;
                    z-index: 10;
                }
                li:not(:first-child) {
                    height: 390px;
                }
                li:nth-child(1) {
                    background: url('../assets/imgs/coordinateddevelopment/drop_list_1.webp') 50% 50%;
                    background-size: cover;
                }
                li:nth-child(2) {
                    background: url('../assets/imgs/coordinateddevelopment/drop_list_2.png') 50% 50%;
                    background-size: cover;
                }
                li:nth-child(3) {
                    background: url('../assets/imgs/coordinateddevelopment/drop_list_3.webp') 50% 50%;
                    background-size: cover;
                }
                li:nth-child(4) {
                    background: url('../assets/imgs/coordinateddevelopment/drop_list_4.webp') 50% 50%;
                    background-size: cover;
                }
                li:nth-child(5) {
                    background: url('../assets/imgs/coordinateddevelopment/drop_list_5.webp') 50% 50%;
                    background-size: cover;
                }
            }
            .drop_bottom_box {
                @include flexrl();
                margin-top: 160px;
                img {
                    width: 50%;
                }
                .bottom_right {
                    width: 46%;
                    text-align: left;
                    .title {
                        font-size: 24px;
                        font-weight: bold;
                        color: #161616;
                        margin-bottom: 20px;
                    }
                    .content {
                        font-size: 13px;
                        color: #464949;
                        line-height: 20px;
                        margin-bottom: 90px;
                    }
                }
            }
        }
    }
    .features_container {
        margin-top: 160px;
        .Features_box {
            .features_list {
                display: grid;
                grid-template-columns: repeat(2, 46%);
                justify-content: space-between;
                align-content: space-between;
                grid-auto-rows: auto;
                grid-row-gap: 60px;
                text-align: left;
                li {
                    padding: 30px 30px 48px;
                    border-radius: 12px;
                    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.10);
                    .title {
                        font-size: 24px;
                        font-weight: bold;
                        color: #161616;
                        margin-bottom: 20px;
                    }
                    .content {
                        font-size: 13px;
                        color: #464949;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1160px){
}
@media screen and (max-width: 820px){
    .coordinateddevelopment {
        .drop_system_container {
            .drop_system_box {
                .drop_bottom_box {
                    flex-direction: column;
                    margin-top: 30px;
                    img {
                        width: 90%;
                        margin-bottom: 40px;
                    }
                    .bottom_right {
                        width: 90%;
                        text-align: center;
                        .content {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        .features_container {
            margin-top: 60px;
            .Features_box {
                .description {
                    margin-bottom: 50px;
                }
                .features_list {
                    grid-template-columns: repeat(1, 100%);
                    grid-row-gap: 30px;
                }
            }
        }
    }
}
@media screen and (max-width: 768px){}
</style>